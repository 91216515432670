import React from "react";

function Rohusoometsad_nimega_alumine(props) {
  return (
    <svg viewBox="0 0 156.79 34.09" {...props}>
      <g data-name="Layer 2">
        <path
          d="M 113.989 7.059 C 111.209 19.059 97.287 13.081 97.237 13.801 C 97.246 13.596 97.273 13.392 97.317 13.191 C 97.187 13.731 96.497 16.021 95.707 18.531 L 51.996 30.254 L 94 29.09 L 48.11 29.09 L 14.76 26.09 L 58.162 30.849 L 153.237 4.49 L 153.677 4.49 L 64.68 9.82 L 96 9.82 L 136.657 7.407 M 0 0 L 11.14 30.76 L 47.89 34.09 L 94.11 34.09 L 123.87 32.76 L 149.42 29.62 C 149.42 29.62 152.14 21.12 152.14 20.62 C 152.14 20.12 156.79 0.17 156.79 0.17 L 95.61 4.82 L 64.85 4.82 L 30.09 3.66 L 0 0 Z"
          fill="#5b55a1"
          id="background"
        />
        <text
          transform="translate(64.33 17.05)"
          fontSize={8}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          {"ROHUSOO-"}
          <tspan x={-2.2} y={9.6}>
            {" METSAD"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

const MemoRohusoometsad_nimega_alumine = React.memo(
  Rohusoometsad_nimega_alumine
);
export default MemoRohusoometsad_nimega_alumine;

import React from "react";

function Nõmmemetsad_nimega(props) {
  return (
    <svg viewBox="0 0 204.51 161.95" {...props}>
      <g data-name="Layer 2" id="prefix__Layer_2">
        <path
          d="M125.61 6.44l7.23 7.1.35.35.42.27 13 8.48.27.18.29.14 15.47 7.48.18.09.2.07 10.81 4.16.38.15.4.08 24.56 5.08-3.37 37.39-78.24 5.68-2.43.17-1.36 2-12 18L67 146.5l-24.57 10L7.09 141l11.6-20.39 16.74-27.56 16-22.15L78 43.82 97.9 25.2l27.71-18.76M126.2 0L94.77 21.28l-20.28 19-26.94 27.39-16.29 22.61-16.88 27.77L0 143.33 42.4 162l27.72-11.31 35.63-44.39 12.14-18.13 82.47-6 4.15-46.06-28.93-6-10.81-4.15-15.46-7.48-13-8.48L126.2 0z"
          fill="#f1e511"
        />
        <path 
          d="M 125.61 6.44 L 132.84 13.54 L 133.19 13.89 L 133.61 14.16 L 146.61 22.64 L 146.88 22.82 L 147.17 22.96 L 162.64 30.44 L 162.82 30.53 L 163.02 30.6 L 173.83 34.76 L 174.21 34.91 L 174.61 34.99 L 94.163 87.165 L 195.938 79.279 L 117.56 83.14 L 115.13 83.31 L 113.77 85.31 L 100.197 75.932 L 80.738 82.974 L 42.43 156.5 L 84.969 83.252 L 88.516 67.282 L 35.43 93.05 L 118.383 85.171 L 114.01 40.479 L 107.599 81.714 L 198.564 45.401 M 126.2 0 L 94.77 21.28 L 74.49 40.28 L 47.55 67.67 L 31.26 90.28 L 14.38 118.05 L 0 143.33 L 42.4 162 L 70.12 150.69 L 105.75 106.3 L 117.89 88.17 L 200.36 82.17 L 204.51 36.11 L 175.58 30.11 L 164.77 25.96 L 149.31 18.48 L 136.31 10 L 126.2 0 Z"
          fill="#f1e511"
          id="background"
        />
        <text
          transform="translate(70 66.56)"
          fontSize={9}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          {"N\xD5MMEMETSAD"}
        </text>
      </g>
    </svg>
  );
}

const MemoNõmmemetsad_nimega = React.memo(Nõmmemetsad_nimega);
export default MemoNõmmemetsad_nimega;

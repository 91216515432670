import React from "react";

function Kuivendatud_siirdesoo_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 103.62 54.32" {...props}>
      <defs>
        <style>{".prefix__cls-3{letter-spacing:-.03em}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          strokeWidth={0.75}
          d="M54 15.94l37.91 7.15 11.14 30.76-54.2-8.81-30.54-7.72L.74.67l9.62 3.57L54 15.94z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <text
          transform="translate(48.75 33.73)"
          fontSize={7}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Kuivendatud"}
          <tspan x={0} y={8}>
            {"siirdesoo"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

const MemoKuivendatud_siirdesoo_alltüüp_nimega = React.memo(
  Kuivendatud_siirdesoo_alltüüp_nimega
);
export default MemoKuivendatud_siirdesoo_alltüüp_nimega;

import React from "react";

function MustikaKõdusoo_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 110.78 69.03" {...props}>
      <g data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          strokeWidth={0.75}
          d="M110.16 68.49l-27.64-7.04-25.44-10.81-25.44-16.9-17.95-16.35L1.05.76 47 16.65l30.53 7.72 16.25 2.64 16.38 41.48z"
          data-name="kkt piir"
        />
        <text
          transform="translate(61 37)"
          fontSize={8}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="allt\xFC\xFCbid nim."
        >
          <tspan>{"Mustika- "}</tspan>
          <tspan x={0} y={9}>
            {"k\xF5dusoo"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

const MemoMustikaKõdusoo_alltüüp_nimega = React.memo(
  MustikaKõdusoo_alltüüp_nimega
);
export default MemoMustikaKõdusoo_alltüüp_nimega;

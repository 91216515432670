import React from "react";

function Base(props) {
  return (
    <svg viewBox="0 0 361.14 418.5" {...props}>
      <g  data-name="Layer 2">
        <path
          fill="#dadada"
          d="M343.51 408.53l5.5-39.74 3.1-62.91-1.56-41.45-6.65-60.97-6.7-43.23-6.6-28.21 30.54-67.34-31.43-48.22-17.29 9.31-24.94 7.65-18.62 2.66h-36.25l-28.93-5.99-26.27-11.63-12.97-8.48L154.3 0l-31.43 21.28-20.28 18.96-26.94 27.43-16.29 22.61-30.26 51.55-15.97 41.4-8.47 33.09L0 248.91v41.07l5.32 34.75 7.81 27.43 44.07 23.61 59.69 22.61 43.39 11.48 48.56 7.15 49.21 1.49 45.89-2.66 30.1-4.32 9.47-2.99z"
          data-name="hall taust"
        />
      </g>
    </svg>
  );
}

const MemoBase = React.memo(Base);
export default MemoBase;

import React from "react";

function Samblasoometsad_nimega(props) {
  return (
    <svg viewBox="0 0 179.61 145.99" {...props}>
      <g data-name="Layer 2" id="prefix__Layer_2">
        <path
          d="M5.53 5.26L26 6.29l15.1 4.4 4.38 15 .42 1.45 1.16 1L75 51.55l1 .82 1.25.25 32.92 6.65.49.1h19.06l26.18 23.19.17.15.19.13 16.39 12 2 15.58-5.21 30-7.58-.89L119 128.25l-59.19-22.41L17.9 83.39l-7.15-25.1-5.22-34.06v-19M.53 0v24.61l5.32 34.75 7.82 27.43 44.06 23.61 59.69 22.6 43.4 11.47 12.64 1.5 6.15-35.42-2.33-18.46-18.12-13.3-27.6-24.44H111.1l-32.92-6.63-27.93-23.44-5.16-17.63L26.8 1.33.53 0z"
          fill="#91841c"
        />
        <path
          d="M 5.53 5.26 L 77.084 114.926 L 41.1 10.69 L 45.48 25.69 L 45.9 27.14 L 47.06 28.14 C 53.552 106.868 75 51.55 75 51.55 L 57.788 39.44 L 59.928 86.577 L 110.17 59.27 L 110.66 59.37 L 148.063 136.609 L 155.9 82.56 L 156.07 82.71 L 156.26 82.84 L 172.65 94.84 L 165.822 95.336 L 160.249 97.533 L 161.86 139.53 L 175.005 93.841 L 131.8 58.322 L 54.186 51.362 L 10.75 58.29 L 76.302 113.947 L 28.91 4.908 M 0.53 0 L 0.53 24.61 L 5.85 59.36 L 13.67 86.79 L 57.73 110.4 L 117.42 133 L 160.82 144.47 L 173.46 145.97 L 179.61 110.55 L 177.28 92.09 L 159.16 78.79 L 131.56 54.35 L 111.1 54.35 L 78.18 47.72 L 50.25 24.28 L 45.09 6.65 L 26.8 1.33 L 0.53 0 Z"
          fill="#91841c"
          id="background"
        />
        <text
          transform="translate(29.9 78.3)"
          fontSize={9}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          {"SAMBLASOOMETSAD"}
        </text>
      </g>
    </svg>
  );
}

const MemoSamblasoometsad_nimega = React.memo(Samblasoometsad_nimega);
export default MemoSamblasoometsad_nimega;

import React from "react";
import { navigate } from "gatsby";

function Osja_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 136.06 91" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M.65 4.87L18.04.52l35.8 8.42 34.7 30.48 46.77 21.95-8.64 12.64-7.82 16.46-33.92-3.33-27.76.33-18.13-13.3-27.6-24.44-1.83-17.96L.65 4.87z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M118.85 90.47l-.86-.51" />
          <path
            strokeDasharray="2.02 2.02"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M116.25 88.93L89.12 72.9l-15.16-3.55H54.84L32.89 51.39l-1-9.64-3.84-11.23"
          />
          <path className="prefix__cls-2" d="M27.73 29.56l-.33-.95.92-.4" />
          <path
            strokeDasharray="2.02 2.02"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M30.17 27.41l6.38-2.79 16.79 6.32 17.63 14.55 21.78 10.05"
          />
          <path className="prefix__cls-2" d="M93.67 55.96l.91.42-.52.86" />
          <path
            strokeDasharray="1.8 1.8"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M93.14 58.78l-6.92 11.58"
          />
          <path
            className="prefix__cls-2"
            d="M85.76 71.13l-.51.86M94.58 56.38l.99.12"
          />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M97.61 56.75l35.69 4.37"
          />
          <path
            className="prefix__cls-2"
            d="M134.32 61.25l.99.12M57.17 87.47l-.13-.99"
          />
          <path
            strokeDasharray="1.81 1.81"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M56.81 84.69l-1.73-13.45"
          />
          <path
            className="prefix__cls-2"
            d="M54.97 70.34l-.13-.99M53.84 8.94l-.02 1"
          />
          <path
            strokeDasharray="1.82 1.82"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M53.78 11.76l-.4 17.27"
          />
          <path
            className="prefix__cls-2"
            d="M53.36 29.94l-.02 1M11.44 49.73l1 .08"
          />
          <path
            strokeDasharray="2.17 2.17"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M14.6 49.98l16.21 1.25"
          />
          <path
            className="prefix__cls-2"
            d="M31.89 51.32l1 .07M27.4 28.61l-.74-.66"
          />
          <path
            strokeDasharray="1.99 1.99"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M25.17 26.63L2.14 6.19"
          />
          <path className="prefix__cls-2" d="M1.39 5.53l-.74-.66" />
        </g>
        <g
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
        >
          <text onClick={() => navigate("/mets/tarna")} transform="translate(51.1 59.59)">{"Tarna"}</text>
          <text onClick={() => navigate("/mets/osja")} transform="translate(34.8 41.41)">{"Osja"}</text>
        </g>
      </g>
    </svg>
  );
}

const MemoOsja_alltüüp_nimega = React.memo(Osja_alltüüp_nimega);
export default MemoOsja_alltüüp_nimega;


import React from "react";

function Laanemetsad_nimega(props) {
  return (
    <svg viewBox="0 0 218.15 129.69" {...props}>
      <g data-name="Layer 2" id="prefix__Layer_2">
        <path
          d="M122.63 5.55L204.81 17l-1.53 3.39-.7 1.55.39 1.64 6.55 28 2.92 20.13-18.64 1.54-44.88-2.42-1.92-.11-1.49 1.21-22.28 17.95-1.75 1.41-.11 2.24-1.37 27.1-24.69 4-38.4-6.4-37.18-6.37L5.44 97.08 7.1 89.2l45-40.36 45.18-1.39 2-.06 1.39-1.39 14-14.19.91-.91.34-1.24 6.75-24.07M119 0l-7.92 28.27-14 14.18-47 1.45L2.55 86.57 0 98.65l17.29 17.85 38.8 6.65 39.22 6.54 29.5-4.77 1.56-31.14 22.28-18 45.22 2.44 24.28-2-3.72-25.6-6.59-28.21 4.27-9.41L119 0z"
          fill="#7e2180"
        />
        <path
          d="M 214.804 73.705 L 204.81 17 L 203.28 20.39 L 202.58 21.94 L 202.97 23.58 L 209.52 51.58 L 212.44 71.71 L 193.8 73.25 L 148.92 70.83 L 147 70.72 L 145.51 71.93 L 123.23 89.88 L 121.48 91.29 L 120.091 81.674 L 65.942 112.616 L 95.31 124.63 L 50.982 99.316 L 19.73 111.86 L 77.541 105.319 L 7.1 89.2 L 52.1 48.84 L 4.807 88.599 L 73.11 122.121 L 153.764 74.007 C 153.764 74.007 119.006 33.088 115.091 21.8 L 115.58 30.9 L 115.92 29.66 L 150.437 72.25 M 119 0 L 111.08 28.27 L 97.08 42.45 L 50.08 43.9 L 2.55 86.57 L 0 98.65 L 17.29 116.5 L 56.09 123.15 L 95.31 129.69 L 124.81 124.92 L 126.37 93.78 L 148.65 75.78 L 193.87 78.22 L 218.15 76.22 L 214.43 50.62 L 207.84 22.41 L 212.11 13 L 119 0 Z"
          fill="#7e2180"
          id="background"
        />
        <text
          transform="translate(95 62.5)"
          fontSize={9}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          {"LAANEMETSAD"}
        </text>
      </g>
    </svg>
  );
}

const MemoLaanemetsad_nimega = React.memo(Laanemetsad_nimega);
export default MemoLaanemetsad_nimega;

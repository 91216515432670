import React from "react";

function Kuivendatud_madalsoo_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 96.56 34.91" {...props}>
      <g data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          strokeWidth={0.75}
          d="M.56.45L11.7 31.21l36.75 3.32h46.22l1.5-29.26H65.41L30.66 4.1.56.45z"
          data-name="kkt piir"
        />
        <text
          transform="translate(18.51 16.75)"
          fontSize={8}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="allt\xFC\xFCbid nim."
        >
          <tspan letterSpacing="-.03em">{"Kuivendatud"}</tspan>
          <tspan x={0} y={8}>
            {"madalsoo"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

const MemoKuivendatud_madalsoo_alltüüp_nimega = React.memo(
  Kuivendatud_madalsoo_alltüüp_nimega
);
export default MemoKuivendatud_madalsoo_alltüüp_nimega;

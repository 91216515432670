import React from "react";

function JkMs_nimega(props) {
  return (
    <svg viewBox="0 0 56.09 58.04" {...props}>
      <g data-name="Layer 2">
        <path
          fill="#e8c9e1"
          d="M17.29 51.39l38.8 6.65-32.87-34.36L26.46 0 2.55 21.46 0 33.54l17.29 17.85 38.8 6.65"
          data-name="Layer 12"
        />
        <text
          transform="translate(5 35.5)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="allt\xFC\xFCbid nim."
        >
          {"Jk-ms"}
        </text>
      </g>
    </svg>
  );
}

const MemoJkMs_nimega = React.memo(JkMs_nimega);
export default MemoJkMs_nimega;

import React from "react";

function Angervaksa_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 123.72 103.58" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M5.94.57l39.22 6.54 29.5-4.76 44.01 42.12-6.43 25.05 10.87 23.06-12.2 10.47-28.93-3.82-46.77-21.95L.51 46.8l.77-22.73L5.94.57z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M81.98 99.23l-.27-.96" />
          <path
            strokeDasharray="2.2 2.2"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M81.1 96.15l-3.34-11.64"
          />
          <path className="prefix__cls-2" d="M77.46 83.45l-.27-.96-.91-.42" />
          <path
            strokeDasharray="1.95 1.95"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M74.51 81.24l-17.58-8.17-21.06-17.18-33.45-8.6"
          />
          <path
            className="prefix__cls-2"
            d="M1.48 47.05l-.97-.25M77.19 82.49l.98.15"
          />
          <path
            strokeDasharray="1.98 1.98"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M80.14 82.94l11.71 1.77 5.69-4.06"
          />
          <path className="prefix__cls-2" d="M98.35 80.08l.81-.58-.48-.88" />
          <path
            strokeDasharray="2.01 2.01"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M97.71 76.86l-5.86-10.67 2.71-10.58"
          />
          <path className="prefix__cls-2" d="M94.81 54.64l.25-.97-.73-.68" />
          <path
            strokeDasharray="2.08 2.08"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M92.79 51.58L75.17 35.32"
          />
          <path className="prefix__cls-2" d="M74.4 34.62l-.73-.68-.99.18" />
          <path
            strokeDasharray="1.99 1.99"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M70.72 34.47l-11.8 2.13-18.74-2.41"
          />
          <path className="prefix__cls-2" d="M39.19 34.06l-.99-.12-.34.94" />
          <path
            strokeDasharray="1.86 1.86"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M37.24 36.63l-2.03 5.73.56 11.6"
          />
          <path
            className="prefix__cls-2"
            d="M35.82 54.89l.05 1M95.06 53.67l.93-.36"
          />
          <path
            strokeDasharray="2.12 2.12"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M97.97 52.54l18.78-7.32"
          />
          <path
            className="prefix__cls-2"
            d="M117.74 44.83l.93-.36M73.67 33.94l.03-1"
          />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M73.76 30.97l.84-26.64"
          />
          <path
            className="prefix__cls-2"
            d="M74.63 3.35l.03-1M38.2 33.94l-.7-.72"
          />
          <path
            strokeDasharray="1.93 1.93"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M36.16 31.83L7.31 1.99"
          />
          <path className="prefix__cls-2" d="M6.63 1.29L5.94.57" />
        </g>
        <text 
          transform="translate(43 59)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Angervaksa"}
        </text>
      </g>
    </svg>
  );
}

const MemoAngervaksa_alltüüp_nimega = React.memo(Angervaksa_alltüüp_nimega);
export default MemoAngervaksa_alltüüp_nimega;

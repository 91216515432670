import React from "react";

function Leesikaloo_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 134.09 66.91" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M4.71 20.28h36.25l18.62-2.66 24.94-7.65L101.81.66l31.43 48.22L.55 66.33l4.16-46.05z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M52.71 46.55l-.22-.98" />
          <path
            strokeDasharray="1.71 1.71"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M52.11 43.9l-2.09-9.18"
          />
          <path
            className="prefix__cls-2"
            d="M49.83 33.89l-.23-.98M94.5 18.84l.58.81"
          />
          <path
            strokeDasharray="1.87 1.87"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M96.17 21.16l10.39 14.4"
          />
          <path
            className="prefix__cls-2"
            d="M107.1 36.32l.59.81M4.71 20.28l.96.27"
          />
          <path
            strokeDasharray="2.04 2.04"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M7.63 21.1L49.6 32.91l42.97-13.47"
          />
          <path className="prefix__cls-2" d="M93.54 19.14l.96-.3.37-.93" />
          <path
            strokeDasharray="1.96 1.96"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M95.6 16.1l5.47-13.61"
          />
          <path
            className="prefix__cls-2"
            d="M101.44 1.59l.37-.93M.55 66.34l.93-.39"
          />
          <path
            strokeDasharray="2 2"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M3.32 65.18L35.3 51.76l17.41-5.21 54.98-9.42 23.73 10.91"
          />
          <path className="prefix__cls-2" d="M132.33 48.46l.91.42" />
        </g>
        <text
          transform="translate(55.6 37.45)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Leesikaloo"}
        </text>
      </g>
    </svg>
  );
}

const MemoLeesikaloo_alltüüp_nimega = React.memo(Leesikaloo_alltüüp_nimega);
export default MemoLeesikaloo_alltüüp_nimega;

import React from "react";

function KrMs_nimega(props) {
  return (
    <svg viewBox="0 0 47.4 42.6" {...props}>
      <g data-name="Layer 2">
        <path
          fill="#dcdcdc"
          d="M0 12.64L13.47 0l10.47 2.33 14.47 13.3L47.4 42.6 21.45 20.17 0 12.64 13.47 0"
          data-name="Layer 12"
        />
        <text
          transform="translate(9.47 15.58)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="allt\xFC\xFCbid nim."
        >
          {"Kr-ms"}
        </text>
      </g>
    </svg>
  );
}

const MemoKrMs_nimega = React.memo(KrMs_nimega);
export default MemoKrMs_nimega;

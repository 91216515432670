
import React from "react";

function JänesekapsaKõdusoo_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 177.38 59.77" {...props}>
      <g data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          strokeWidth={0.75}
          d="M176.82 5.51l-18.95 53.87-78.34-3.64-28.85-5.2-33.71-8.59L.59.48l37.95 6.17 36.75 3.32h46.22l29.77-1.33 25.54-3.13z"
          data-name="kkt piir"
        />
        <text
          transform="translate(77.46 27.75)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="allt\xFC\xFCbid nim."
        >
          {"J\xE4nesekapsa- "}
          <tspan x={0} y={10}>
            {"k\xF5dusoo"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

const MemoJänesekapsaKõdusoo_alltüüp_nimega = React.memo(
  JänesekapsaKõdusoo_alltüüp_nimega
);
export default MemoJänesekapsaKõdusoo_alltüüp_nimega;

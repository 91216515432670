import React from "react";

function Naadi_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 104.04 92.33" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M93.84.95l3 17.63 6.65 60.96-33.7.95-25.27 11.25L.51 49.62l1.55-31.15L24.34.51l45.23 2.44 24.27-2z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M24.34.51l.68.74" />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M26.4 2.76l15.87 17.42"
          />
          <path className="prefix__cls-2" d="M42.96 20.94l.67.74-.83.57" />
          <path
            strokeDasharray="1.79 1.79"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M41.33 23.26l-8.11 5.55"
          />
          <path className="prefix__cls-2" d="M32.48 29.32l-.82.56-.02 1" />
          <path
            strokeDasharray="2.03 2.03"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M31.6 32.92l-.23 11.19"
          />
          <path className="prefix__cls-2" d="M31.35 45.12l-.02 1 .72.7" />
          <path
            strokeDasharray="2 2"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M33.49 48.21l16.57 15.98"
          />
          <path className="prefix__cls-2" d="M50.78 64.88l.72.7.92-.39" />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M54.25 64.44l18.87-7.84 25.94.31"
          />
          <path
            className="prefix__cls-2"
            d="M100.05 56.92l1 .01M43.63 21.68l1 .03"
          />
          <path
            strokeDasharray="2.07 2.07"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M46.7 21.77l48.66 1.4"
          />
          <path
            className="prefix__cls-2"
            d="M96.39 23.2l1 .03M31.33 46.12l-1 .12"
          />
          <path
            strokeDasharray="1.93 1.93"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M28.41 46.45L2.47 49.39"
          />
          <path
            className="prefix__cls-2"
            d="M1.5 49.5l-.99.11M51.5 65.58l-.26.96"
          />
          <path
            strokeDasharray="1.93 1.93"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M50.74 68.41l-5.72 21.43"
          />
          <path className="prefix__cls-2" d="M44.77 90.77l-.25.97" />
        </g>
        <text
          transform="translate(49.64 44.54)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Naadi"}
        </text>
      </g>
    </svg>
  );
}

const MemoNaadi_alltüüp_nimega = React.memo(Naadi_alltüüp_nimega);
export default MemoNaadi_alltüüp_nimega;

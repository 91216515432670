import React from "react";

function Siirdesoo_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 123.03 99.42" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M120.2 44.99l2.33 18.46-6.16 35.41-12.63-1.49-43.4-11.47L.65 63.28l19.12-49.27L21.1.6l32.92 6.65h20.45l27.6 24.44 18.13 13.3z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M120.2 44.99h-1" />
          <path
            strokeDasharray="2.09 2.09"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M117.1 44.99h-15.7"
          />
          <path className="prefix__cls-2" d="M100.36 44.99h-1l.08 1" />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M99.59 47.95l.66 8.35-1.83 12.64 16.93 28.23"
          />
          <path
            className="prefix__cls-2"
            d="M115.86 98.01l.51.86M21.1.6l.79.61"
          />
          <path
            strokeDasharray="1.99 1.99"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M23.46 2.43l27.13 21.12 14.96 2.65 8.98.55"
          />
          <path className="prefix__cls-2" d="M75.53 26.81l.99.06-.1-.99" />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M76.21 23.92l-1.53-14.7"
          />
          <path
            className="prefix__cls-2"
            d="M74.58 8.24l-.11-.99M50.59 23.54l-.27.97"
          />
          <path
            strokeDasharray="1.87 1.87"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M49.82 26.31l-6.7 24.3"
          />
          <path className="prefix__cls-2" d="M42.87 51.51l-.27.97.95.32" />
          <path
            strokeDasharray="1.94 1.94"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M45.38 53.44l23.61 8.24 27.51 6.79"
          />
          <path
            className="prefix__cls-2"
            d="M97.44 68.7l.98.24M99.36 44.99l-.79-.62"
          />
          <path
            strokeDasharray="2.09 2.09"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M96.94 43.07L78.12 28.14"
          />
          <path
            className="prefix__cls-2"
            d="M77.31 27.49l-.79-.62M42.6 52.48l-.96.25"
          />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M39.73 53.22L2.57 62.79"
          />
          <path className="prefix__cls-2" d="M1.62 63.03l-.97.25" />
        </g>
        <text
          transform="translate(54.13 43.57)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Siirdesoo"}
        </text>
      </g>
    </svg>
  );
}

const MemoSiirdesoo_alltüüp_nimega = React.memo(Siirdesoo_alltüüp_nimega);
export default MemoSiirdesoo_alltüüp_nimega;

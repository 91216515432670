import React from "react";

function Lodu_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 112.63 93.9" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M.65 36.35l7.81-16.46 8.65-12.64 28.93 3.82L58.23.6l26.16 13.52 27.71 1.78-1.55 29.26-4.82 39.24-9.48 2.99-30.09 4.33-28.55 1.65-1.99-35.8L.65 36.35z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M58.23.6l.46.89" />
          <path
            d="M59.62 3.29l10.47 20.25s14.85 7.65 15.3 7.76 17.82.87 23.82 1.13"
            strokeDasharray="2.03 2.03"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
          />
          <path
            className="prefix__cls-2"
            d="M110.22 32.47l1 .05M70.09 23.54l-.83.56"
          />
          <path
            strokeDasharray="1.95 1.95"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M67.64 25.18l-4.87 3.24-12.55-1.34"
          />
          <path className="prefix__cls-2" d="M49.25 26.98l-1-.11-.63.77" />
          <path
            strokeDasharray="2.04 2.04"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M46.32 29.21l-2.72 3.31-2.32 6.53"
          />
          <path className="prefix__cls-2" d="M40.94 40.01l-.33.94.86.51" />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M43.23 42.5l13.26 7.84"
          />
          <path className="prefix__cls-2" d="M57.37 50.86l.86.51.11.99" />
          <path
            strokeDasharray="1.78 1.78"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M58.53 54.14l1.05 9.76"
          />
          <path className="prefix__cls-2" d="M59.67 64.78l.11 1 1-.02" />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M62.83 65.71l18.01-.38 25.76-4.03"
          />
          <path
            className="prefix__cls-2"
            d="M107.62 61.14l.99-.15M.65 36.35l.99.11"
          />
          <path
            strokeDasharray="2.01 2.01"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M3.64 36.69l34.97 4.03"
          />
          <path
            className="prefix__cls-2"
            d="M39.61 40.83l1 .12M59.78 65.78l-.62.78"
          />
          <path
            strokeDasharray="1.96 1.96"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M57.92 68.09L38.85 91.83"
          />
          <path
            className="prefix__cls-2"
            d="M38.23 92.59l-.62.78M17.11 7.25l.84.53"
          />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M19.68 8.87l26.86 16.92"
          />
          <path className="prefix__cls-2" d="M47.41 26.34l.84.53" />
        </g>
        <text
          transform="translate(68.3 46.42)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Lodu"}
        </text>
      </g>
    </svg>
  );
}

const MemoLodu_alltüüp_nimega = React.memo(Lodu_alltüüp_nimega);
export default MemoLodu_alltüüp_nimega;

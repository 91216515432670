import React from "react";

function Madalsoo_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 103.58 62.35" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M.58 54.7l6.15-35.41L4.4.83 32.17.5l33.92 3.33 34.97 21.22 1.99 35.8-17.34 1-49.22-1.49L.58 54.7z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M103.05 60.85l-.85-.52" />
          <path
            strokeDasharray="1.88 1.88"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M100.59 59.36l-21.7-13.13"
          />
          <path className="prefix__cls-2" d="M78.09 45.74l-.86-.51-.04-1" />
          <path
            strokeDasharray="2.08 2.08"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M77.11 42.15l-.46-11.4"
          />
          <path className="prefix__cls-2" d="M76.61 29.71l-.05-1-.87-.48" />
          <path
            strokeDasharray="2.1 2.1"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M73.85 27.21l-13.78-7.6"
          />
          <path className="prefix__cls-2" d="M59.15 19.11l-.88-.49.47-.88" />
          <path
            strokeDasharray="2.1 2.1"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M59.73 15.88l5.4-10.24"
          />
          <path
            className="prefix__cls-2"
            d="M65.62 4.71l.47-.88M58.28 18.62l-.98-.2"
          />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M55.37 18.03l-15.05-3.06-11.12 1.55"
          />
          <path className="prefix__cls-2" d="M28.23 16.66l-.99.14-.07.99" />
          <path
            strokeDasharray="2.02 2.02"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M27.04 19.81l-1.22 19.2"
          />
          <path className="prefix__cls-2" d="M25.75 40.02l-.06 1 .99.12" />
          <path
            strokeDasharray="1.99 1.99"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M28.65 41.4l24.53 3.16 22.05.61"
          />
          <path
            className="prefix__cls-2"
            d="M76.23 45.2l1 .03M.58 54.7l.88-.47"
          />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M3.25 53.25l20.66-11.27"
          />
          <path
            className="prefix__cls-2"
            d="M24.81 41.49l.88-.48M27.24 16.79l-.82-.57"
          />
          <path
            strokeDasharray="1.99 1.99"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M24.79 15.08L6.04 1.98"
          />
          <path className="prefix__cls-2" d="M5.22 1.41L4.4.83" />
        </g>
        <text
          transform="translate(32.1 35.74)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Madalsoo"}
        </text>
      </g>
    </svg>
  );
}

const MemoMadalsoo_alltüüp_nimega = React.memo(Madalsoo_alltüüp_nimega);
export default MemoMadalsoo_alltüüp_nimega;

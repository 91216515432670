import React from "react";

function Kanarbiku_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 119.18 133.09" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M70.83 121.24l35.63-44.4 12.13-18.12L85.94.8 75.2 10.84 48.26 38.27 31.97 60.88 15.09 88.65.71 113.92l42.4 18.63 27.72-11.31z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M70.83 121.24l.18-.99" />
          <path
            strokeDasharray="2.04 2.04"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M71.36 118.25l4.82-27.11"
          />
          <path className="prefix__cls-2" d="M76.35 90.13l.18-.98-.86.52" />
          <path
            strokeDasharray="2.08 2.08"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M73.89 90.74l-9.8 5.89"
          />
          <path className="prefix__cls-2" d="M63.2 97.17l-.86.51-.93-.36" />
          <path
            strokeDasharray="1.91 1.91"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M59.63 96.61l-20.41-8.07"
          />
          <path
            className="prefix__cls-2"
            d="M38.33 88.19l-.93-.37M86.1.65l-.09.99"
          />
          <path
            strokeDasharray="2.01 2.01"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M85.82 3.64l-2.53 26.54-21.17 23.61L37.4 87.82 2.34 112.76"
          />
          <path
            className="prefix__cls-2"
            d="M1.52 113.34l-.81.58M83.29 30.18l.59.8"
          />
          <path
            strokeDasharray="2.07 2.07"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M85.11 32.66l8.27 11.26 6.39 12.49"
          />
          <path className="prefix__cls-2" d="M100.24 57.33l.45.89-.5.87" />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M99.19 60.79l-5.37 9.18-15.96 17.7"
          />
          <path
            className="prefix__cls-2"
            d="M77.2 88.41l-.67.74M100.69 58.22l1 .03"
          />
          <path
            strokeDasharray="1.77 1.77"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M103.46 58.3l13.25.37"
          />
          <path className="prefix__cls-2" d="M117.6 58.69l.99.03" />
        </g>
        <text
          transform="translate(52.22 73.61)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Kanarbiku"}
        </text>
      </g>
    </svg>
  );
}

const MemoKanarbiku_alltüüp_nimega = React.memo(Kanarbiku_alltüüp_nimega);
export default MemoKanarbiku_alltüüp_nimega;

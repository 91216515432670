import React from "react";

function Rabastuvad_metsad_nimega(props) {
  return (
    <svg viewBox="0 0 131.02 176.41" {...props}>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          d="M30.93 6.7l37.56 16.5 1.94.85 2-.8 19.38-7.9-3.18 18.22-.15.83.14.83 2 12.38-28 31.18L60.68 81l.92 2.76 6.48 19.45 2.47 7.41 5.7-5.34 11.54-10.87 6.58 1.46 12.53 11.52 17.37 52.12 1.22 11.9h-14.42l-31.18-6.3-25.8-21.65-4.73-16.17-.77-2.63-2.59-.77-18.29-5.32-.56-.16h-.59L5 117.29v-11.35L9.57 74l8.34-32.56 13-34.71M28.1 0l-15 39.9L4.66 73 0 105.58V122l26.27 1.33 18.29 5.32 5.15 17.63 27.94 23.44 32.92 6.65H131l-1.83-17.95-18-53.88-14.4-13.26L86.29 89l-13.46 12.59-6.49-19.45L96 49.16l-2.45-14.74 4.67-27.1-27.72 11.3L28.1 0z"
          fill="#7e7f80"
          id="prefix__kasvukohat\xFC\xFCp"
        />
        <path 
          d="M 98.752 70.764 L 87.117 1.882 L 129.517 20.502 L 157.237 9.202 L 152.567 36.302 L 155.017 51.042 L 125.357 84.022 L 131.847 103.472 L 145.307 90.882 L 155.787 93.162 L 170.187 106.422 L 188.187 160.302 L 190.017 178.252 L 169.587 178.252 L 136.667 171.602 L 108.727 148.162 L 103.577 130.532 L 85.287 125.212 L 59.017 123.882 L 59.017 107.462 L 63.677 74.882 L 72.117 41.782 L 87.117 1.882 L 103.934 46.518 L 101.734 56.465 L 101.052 64.963 L 100.667 67.694 L 100.891 65.836 L 100.059 67.108 L 108.446 70.195 L 124.461 81.32 L 137.514 121.094 L 113.631 139.579 L 108.377 129.172 L 147.941 93.538 L 179.55 145.131 L 170.087 173.292 L 149.262 151.792 L 161.304 146.376 L 142.379 116.581 L 153.387 97.752 L 156.562 171.801 L 135.267 107.162 L 129.567 112.502 L 127.097 105.092 L 120.617 85.642 L 119.697 82.882 L 121.637 80.672 L 110.781 84.064 L 115.404 68.917 L 112.784 82.549 L 112.32 81.524 L 110.019 72.273 L 111.525 74.551 L 109.275 73.101 L 103.803 73.012 L 98.752 70.764 Z"
          fill="#7e7f80"
          style={{transform: "translate(-59px, -2px)"}}
          id="background"
        />
        <g id="prefix__t\xFC\xFCbid_nim_" data-name="t\xFC\xFCbid nim.">
          <text 
            transform="translate(21 47.3)"
            fontSize={9}
            fill="#1d1d1b"
            fontWeight={500}
          >
            {"RABASTUVAD-"}
          </text>
          <text 
            transform="translate(21 57)"
            fontSize={9}
            fill="#1d1d1b"
            fontWeight={500}
          >
            {"METSAD"}
          </text>
        </g>
      </g>
    </svg>
  );
}

const MemoRabastuvad_metsad_nimega = React.memo(Rabastuvad_metsad_nimega);
export default MemoRabastuvad_metsad_nimega;

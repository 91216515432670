import React from "react";

function Sinika_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 99.37 129.93" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M28.6.67L13.63 40.58 5.16 73.66.5 106.25v16.46l26.27 1.33 18.29 5.32 16.02-16.9 12.25-10.2-6.49-19.45 29.65-32.98-2.43-14.74 4.66-27.1L71 19.29 28.6.67z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M28.6.67v1" />
          <path
            strokeDasharray="1.98 1.98"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M28.6 3.65v42.53"
          />
          <path className="prefix__cls-2" d="M28.6 47.17v1l.96.29" />
          <path
            strokeDasharray="2 2"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M31.47 49.04l19.85 6 13.97-5.76L97.47 9.54"
          />
          <path
            className="prefix__cls-2"
            d="M98.1 8.76l.62-.77M29.56 48.46l-.96-.29-.25.97"
          />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M27.85 51.05l-6.01 23.06-3.96 30.17"
          />
          <path className="prefix__cls-2" d="M17.76 105.26l-.13.99.98.16" />
          <path
            strokeDasharray="2.12 2.12"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M20.71 106.74l15.72 2.51"
          />
          <path className="prefix__cls-2" d="M37.48 109.42l.99.16.75-.65" />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M40.78 107.59l11.67-10"
          />
          <path className="prefix__cls-2" d="M53.22 96.93l.76-.65-.25-.97" />
          <path
            strokeDasharray="1.85 1.85"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M53.25 93.52l-1.65-6.25"
          />
          <path className="prefix__cls-2" d="M51.36 86.38l-.26-.97.66-.75" />
          <path
            strokeDasharray="2.13 2.13"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M53.17 83.05l10.54-12.04"
          />
          <path className="prefix__cls-2" d="M64.41 70.2l.66-.75-.18-.98" />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M64.52 66.45l-1-5.42 1.47-9.75"
          />
          <path className="prefix__cls-2" d="M65.14 50.27l.15-.99-.92.38" />
          <path
            strokeDasharray="1.94 1.94"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M62.57 50.4l-11.25 4.64-20.84-6.3"
          />
          <path className="prefix__cls-2" d="M73.33 102.26l-.96-.29" />
          <path
            strokeDasharray="2.03 2.03"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M70.43 101.37l-14.52-4.5"
          />
          <path
            className="prefix__cls-2"
            d="M54.94 96.57l-.96-.29M65.07 69.45l.85-.53"
          />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M67.66 67.85l27.05-16.73"
          />
          <path
            className="prefix__cls-2"
            d="M95.59 50.58l.85-.53M45.06 129.36l-.32-.95"
          />
          <path
            strokeDasharray="2.1 2.1"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M44.08 126.43l-4.97-14.91"
          />
          <path
            className="prefix__cls-2"
            d="M38.78 110.53l-.31-.95M17.63 106.25l-.73.7"
          />
          <path
            strokeDasharray="1.98 1.98"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M15.48 108.32L1.93 121.33"
          />
          <path className="prefix__cls-2" d="M1.22 122.02l-.72.69" />
        </g>
        <text
          transform="translate(27.53 81.03)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Sinika"}
        </text>
      </g>
    </svg>
  );
}

const MemoSinika_alltüüp_nimega = React.memo(Sinika_alltüüp_nimega);
export default MemoSinika_alltüüp_nimega;

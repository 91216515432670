import React, { memo } from "react"

const SchemeLegend = () => (
  <div className="scheme-legend-container">
    <div className="legend-colors">
      <div className="nomme" />
      <div className="loo" />
      <div className="palu" />
      <div className="laane" />
      <div className="salu" />
      <div className="raba" />
      <div className="sooviku" />
      <div className="sambla" />
      <div className="rohu" />
      <div className="kodu" />
    </div>
    <div className="legend-names">
      <span>nõmmemetsad</span>
      <span>loometsad</span>
      <span>palumetsad</span>
      <span>laanemetsad</span>
      <span>salumetsad</span>
      <span>rabastuvad metsad</span>
      <span>soovikumetsad</span>
      <span>samblasoometsad</span>
      <span>rohusoometsad</span>
      <span>kõdusoometsad</span>
    </div>
  </div>
)

export default memo(SchemeLegend)
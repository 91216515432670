import React from "react";

function Salumetsad_nimega(props) {
  return (
    <svg viewBox="0 0 105.01 154.63" {...props}>
      <g data-name="Layer 2" id="prefix__Layer_2">
        <path
          d="M26 5.1l43.27 2.33h.68L89.66 5.8l2.2 13 6.61 60.6 1.54 41-1.88 28.9-21.68-1.38-23.72-12.25-9.38-19.89 6-23.31.72-2.83-2.11-2L5.59 47.07l1.33-26.63L26 5.1M24.32 0L2 18 .49 49.11l44 42.12-6.43 25 10.87 23.05 26.16 13.53 27.71 1.77 2.2-34.09L103.46 79l-6.65-61L93.82.44l-24.28 2L24.32 0z"
          fill="#5eb132"
        />
        <path
          d="M 26 5.1 L 69.27 7.43 L 69.95 7.43 L 44.862 70.555 C 46.428 125.651 47.578 118.065 47.578 118.065 L 76.077 149.299 L 100.01 120.4 L 72.303 114.629 L 76.45 147.92 L 76.488 129.714 L 90.711 79.634 L 49.35 92.47 L 50.07 89.64 L 71.794 4.858 L 5.59 47.07 L 81.087 7.657 L 26 5.1 M 24.32 0 L 2 18 L 0.49 49.11 L 44.49 91.23 L 38.06 116.23 L 48.93 139.28 L 75.09 152.81 L 102.8 154.58 L 105 120.49 L 103.46 79 L 96.81 18 L 93.82 0.44 L 69.54 2.44 L 24.32 0 Z"
          fill="#5eb132"
          id="background"
        />
        <text
          transform="translate(33 68)"
          fontSize={9}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          {"SALUMETSAD"}
        </text>
      </g>
    </svg>
  );
}

const MemoSalumetsad_nimega = React.memo(Salumetsad_nimega);
export default MemoSalumetsad_nimega;

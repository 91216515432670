import React from "react";

function Samblasoometsad_nimega_alumine(props) {
  return (
    <svg viewBox="0 0 153.11 72.99" {...props}>
      <g data-name="Layer 2">
        <path
          d="M 9.78 9.48 L 19.67 13.6 L 19.85 13.6 L 58.68 28.08 L 58.9 28.16 L 59.12 28.22 L 102.77 39.91 L 103 40 L 103.19 40 L 116.834 42.761 L 101.093 51.708 L 99.93 59.28 L 129.018 41.966 L 54.845 23.305 L 12.54 15.58 L 78.43 32.413 M 0 0 L 8.13 18 L 22.43 40.61 L 68 56.37 L 98.93 64.18 L 153.11 73 L 142 42.23 L 104.09 35.08 L 60.42 23.39 L 21.59 9 L 0 0 Z"
          fill="#8a8720"
          id="background"
        />
        <text
          transform="translate(48 46)"
          fontSize={7}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          {"SAMBLASOOMETSAD"}
        </text>
      </g>
    </svg>
  );
}

const MemoSamblasoometsad_nimega_alumine = React.memo(
  Samblasoometsad_nimega_alumine
);
export default MemoSamblasoometsad_nimega_alumine;

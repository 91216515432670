import React from "react";

import { navigate } from "gatsby";

import OrdinateSchemeBase from "../../images/ordinate/misc/Base.js"
import OrdinateSchemeBaseColor from "../../images/ordinate/misc/BaseColor.js"
import AngervaksaChildrenName from "../../images/ordinate/nimega/Angervaksa_alltüüp_nimega.js"
import JänesekapsaChildrenName from "../../images/ordinate/nimega/Jänesekapsa_alltüüp_nimega.js"

import JänesekapsaKõdusooChildrenName from "../../images/ordinate/nimega/JänesekapsaKõdusoo_alltüüp_nimega.js"

import JänesekapsaPohlaName from "../../images/ordinate/nimega/JkPh_nimega.js"
// import JänesekapsaPohlaChildrenName from "../../images/ordinate/nimega/JkPh_alltüüp_nimega.js"
import JänesekapsaMustikaName from "../../images/ordinate/nimega/JkMs_nimega.js"

import KanarbikuChildrenName from "../../images/ordinate/nimega/Kanarbiku_alltüüp_nimega.js"
import KarusamblaChildrenName from "../../images/ordinate/nimega/Karusambla_alltüüp_nimega.js"
import KastikulooChildrenName from "../../images/ordinate/nimega/Kastikuloo_alltüüp_nimega.js"

import KõdusoometsadName from "../../images/ordinate/nimega/Kõdusoometsad_nimega.js"
import KarusamblaMustikaName from "../../images/ordinate/nimega/KrMs_nimega.js"

import KuivendatudLoduChildrenName from "../../images/ordinate/nimega/Kuivendatud_lodu_alltüüp_nimega.js"
import KuivendatudMadalsooChildrenName from "../../images/ordinate/nimega/Kuivendatud_madalsoo_alltüüp_nimega.js"
import KuivendatudRabaChildrenName from "../../images/ordinate/nimega/Kuivendatud_raba_alltüüp_nimega.js"
import KuivendatudSiirdesooChildrenName from "../../images/ordinate/nimega/Kuivendatud_siirdesoo_alltüüp_nimega.js"

import LaanemetsadName from "../../images/ordinate/nimega/Laanemetsad_nimega.js"
import LeesikalooChildrenName from "../../images/ordinate/nimega/Leesikaloo_alltüüp_nimega.js"
import LoduChildrenName from "../../images/ordinate/nimega/Lodu_alltüüp_nimega.js"

import LoometsadName from "../../images/ordinate/nimega/Loometsad_nimega.js"
import MadalsooChildrenName from "../../images/ordinate/nimega/Madalsoo_alltüüp_nimega.js"

// import DirtTypeBorders from "../../images/ordinate/misc/Mulla_alltüübi_piir.js"
// import DirtChildTypeBorders from "../../images/ordinate/misc/Mullatüübipiir.js"

import MustikaChildrenName from "../../images/ordinate/nimega/Mustika_alltüüp_nimega.js"
import MustikaKõdusooChildrenName from "../../images/ordinate/nimega/MustikaKõdusoo_alltüüp_nimega.js"
import NaadiChildrenName from "../../images/ordinate/nimega/Naadi_alltüüp_nimega.js"

import NõmmemetsadName from "../../images/ordinate/nimega/Nõmmemetsad_nimega.js"

import OsjaChildrenName from "../../images/ordinate/nimega/Osja_alltüüp_nimega.js"

import PalumetsadName from "../../images/ordinate/nimega/Palumetsad_nimega.js"


import PohlaChildrenName from "../../images/ordinate/nimega/Pohla_alltüüp_nimega.js"
import RabaChildrenName from "../../images/ordinate/nimega/Raba_alltüüp_nimega.js"
import RabastuvadmetsadName from "../../images/ordinate/nimega/Rabastuvad_metsad_nimega.js"
import RohusoometsadName from "../../images/ordinate/nimega/Rohusoometsad_nimega.js"
import RohusoometsadNameBottom from "../../images/ordinate/nimega/Rohusoometsad_nimega_alumine.js"

import SalumetsadName from "../../images/ordinate/nimega/Salumetsad_nimega.js"
import SamblasoometsadName from "../../images/ordinate/nimega/Samblasoometsad_nimega.js"
import SamblasoometsadNameBottom from "../../images/ordinate/nimega/Samblasoometsad_nimega_alumine.js"

import SamblikuChildrenName from "../../images/ordinate/nimega/Sambliku_alltüüp_nimega.js"
import SiirdesooChildrenName from "../../images/ordinate/nimega/Siirdesoo_alltüüp_nimega.js"
import SinikaChildrenName from "../../images/ordinate/nimega/Sinika_alltüüp_nimega.js"
import SinililleChildrenName from "../../images/ordinate/nimega/Sinilille_alltüüp_nimega.js"

// import Scale from "../../images/ordinate/misc/skaala.js"

import SõnajalaChildrenName from "../../images/ordinate/nimega/Sõnajala_alltüüp_nimega.js"
import SoovikumetsadName from "../../images/ordinate/nimega/Soovikumetsad_nimega.js"

// import TarnaAngervaksaChildrenName from "../../images/ordinate/nimega/tr-an_alltüüp_nimega.js"
import TarnaAngervaksaName from "../../images/ordinate/nimega/TrAn_nimega.js"

const OrdinateScheme = () => (
  <div className="OrdinateScheme">
    <OrdinateSchemeBase className="OrdinateSchemeBase" />
    <OrdinateSchemeBaseColor className="OrdinateSchemeBase BaseColor" />

    <KuivendatudLoduChildrenName className="KuivendatudLoduChildrenName" onClick={() => navigate("/mets/kuivendatud-lodu")} />
    <KuivendatudMadalsooChildrenName className="KuivendatudMadalsooChildrenName" onClick={() => navigate("/mets/kuivendatud-madalsoo")} />

    <KuivendatudSiirdesooChildrenName className="KuivendatudSiirdesooChildrenName" onClick={() => navigate("/mets/kuivendatud-siirdesoo")} />
    <KuivendatudRabaChildrenName className="KuivendatudRabaChildrenName" onClick={() => navigate("/mets/kuivendatud-raba")} />

    <JänesekapsaKõdusooChildrenName className="JänesekapsaKõdusooChildrenName" onClick={() => navigate("/mets/janesekapsa-kodusoo")} />
    <MustikaKõdusooChildrenName className="MustikaKõdusooChildrenName" onClick={() => navigate("/mets/mustika-kodusoo")} />

    <SamblikuChildrenName className="SamblikuChildrenName" onClick={() => navigate("/mets/sambliku")} />
    <KanarbikuChildrenName className="KanarbikuChildrenName" onClick={() => navigate("/mets/kanarbiku")} />

    <SinikaChildrenName className="SinikaChildrenName" onClick={() => navigate("/mets/sinika")} />
    <KarusamblaChildrenName className="KarusamblaChildrenName" onClick={() => navigate("/mets/karusambla")} />

    <RabaChildrenName className="RabaChildrenName" onClick={() => navigate("/mets/raba")} />
    <SiirdesooChildrenName className="SiirdesooChildrenName" onClick={() => navigate("/mets/siirdesoo")} />

    <MadalsooChildrenName className="MadalsooChildrenName" onClick={() => navigate("/mets/madalsoo")} />
    <LoduChildrenName className="LoduChildrenName" onClick={() => navigate("/mets/lodu")} />

    <NaadiChildrenName className="NaadiChildrenName" onClick={() => navigate("/mets/naadi")} />
    <SõnajalaChildrenName className="SõnajalaChildrenName" onClick={() => navigate("/mets/sonajala")} />

    <JänesekapsaPohlaName className="JänesekapsaPohlaName" onClick={() => navigate("/mets/janesekapsa-pohla")} />
    <JänesekapsaMustikaName className="JänesekapsaMustikaName" onClick={() => navigate("/mets/janesekapsa-mustika")} />
    <SinililleChildrenName className="SinililleChildrenName" onClick={() => navigate("/mets/sinilille")} />
    <JänesekapsaChildrenName className="JänesekapsaChildrenName" onClick={() => navigate("/mets/janesekapsa")} />

    <LeesikalooChildrenName className="LeesikalooChildrenName" onClick={() => navigate("/mets/leesikaloo")} />
    <KastikulooChildrenName className="KastikulooChildrenName" onClick={() => navigate("/mets/kastikuloo")} />

    <KarusamblaMustikaName className="KarusamblaMustikaName" onClick={() => navigate("/mets/karusambla-mustika")} />
    {/* <JänesekapsaMustikaName className="JänesekapsaMustikaName" onClick={() => navigate("/mets/janesekapsa-mustika")} /> */}
    {/* <JänesekapsaPohlaName className="JänesekapsaPohlaName" onClick={() => navigate("/mets/janesekapsa-pohla")} /> */}
    <PohlaChildrenName className="PohlaChildrenName" onClick={() => navigate("/mets/pohla")} />
    <MustikaChildrenName className="MustikaChildrenName" onClick={() => navigate("/mets/mustika")} />

    <TarnaAngervaksaName className="TarnaAngervaksaName" onClick={() => navigate("/mets/tarna-angervaksa")} />
    <OsjaChildrenName className="OsjaChildrenName" />
    <AngervaksaChildrenName className="AngervaksaChildrenName" onClick={() => navigate("/mets/angervaksa")} />

    <NõmmemetsadName className="NõmmemetsadName forrest"/>
    <RabastuvadmetsadName className="RabastuvadmetsadName forrest"/>
    <PalumetsadName className="PalumetsadName forrest"/>
    <LoometsadName className="LoometsadName forrest"/>
    <LaanemetsadName className="LaanemetsadName forrest"/>
    <SalumetsadName className="SalumetsadName forrest"/>
    <SoovikumetsadName className="SoovikumetsadName forrest"/>
    <SamblasoometsadName className="SamblasoometsadName forrest"/>
    <RohusoometsadName className="RohusoometsadName forrest"/>
    
    <SamblasoometsadNameBottom className="SamblasoometsadNameBottom forrest"/>
    <RohusoometsadNameBottom className="RohusoometsadNameBottom forrest"/>
    <KõdusoometsadName className="KõdusoometsadName forrest"/>
  </div>
)

export default OrdinateScheme
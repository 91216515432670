
import React from "react";

function JkPh_nimega(props) {
  return (
    <svg viewBox="0 0 70.34 35.91" {...props}>
      <g data-name="Layer 2">
        <path
          fill="#f8b98c"
          d="M0 35.91l2.95-23.83L15.14 1.55 38.53 0l31.81 13.52-47 1.44L0 35.91z"
          data-name="Layer 12"
        />
        <text
          transform="translate(14.99 11.2)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="allt\xFC\xFCbid nim."
        >
          {"Jk-ph"}
        </text>
      </g>
    </svg>
  );
}

const MemoJkPh_nimega = React.memo(JkPh_nimega);
export default MemoJkPh_nimega;

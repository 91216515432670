import React from "react";

function Rohusoometsad_nimega(props) {
  return (
    <svg viewBox="0 0 176.97 93.78" {...props}>
      <g data-name="Layer 2" id="prefix__Layer_2">
        <path
          d="M123.8 6L147 18l.93.49 1.05.06L171.71 20l-1.28 24.15L166 80l-6 1.9-29.48 4.24-45.46 2.63-48.68-1.48L5.8 82.48l5.28-30.41.13-.74-.1-.74-1.62-12.9 21.89-.26L65 40.72l3.5.35 1.5-3.18 7.64-16.1L84.36 12l25.89 3.42 2.21.29 1.7-1.45L123.8 6m-.7-6l-12.2 10.48L82 6.65l-8.67 12.64-7.82 16.46-33.92-3.33-27.77.34 2.33 18.45L0 86.63l35.91 5.65 49.22 1.5L131 91.12l30.1-4.33 9.47-3 4.83-39.24L177 15.3l-27.71-1.78L123.1 0z"
          fill="#5c55a1"
        />
        <path
          d="M 128.671 88.022 L 165.816 38.584 L 166.746 39.074 L 163.657 35.378 L 107.46 42.963 L 160.453 41.274 L 134.664 53.725 L 129.469 67.396 L 127.24 80.563 L 85.06 88.77 L 36.38 87.29 L 67.967 41.268 C 67.967 41.268 -0.613 40.802 11.08 52.07 L 11.21 51.33 L 11.11 50.59 L 87.952 39.415 L 31.38 37.43 L 65 40.72 L 68.5 41.07 L 70 37.89 L 19.743 89.007 L 114.063 89.108 L 126.714 88.154 L 128.924 88.444 L 128.554 83.486 L 109.95 33.843 M 123.1 0 L 110.9 10.48 L 82 6.65 L 73.33 19.29 L 65.51 35.75 L 31.59 32.42 L 3.82 32.76 L 6.15 51.21 L 0 86.63 L 35.91 92.28 L 85.13 93.78 L 131 91.12 L 161.1 86.79 L 170.57 83.79 L 175.4 44.55 L 177 15.3 L 149.29 13.52 L 123.1 0 Z"
          fill="#5c55a1"
          id="background"
        />
        <text
          transform="translate(82 32)"
          fontSize={9}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          {"ROHUSOOMETSAD"}
        </text>
      </g>
    </svg>
  );
}

const MemoRohusoometsad_nimega = React.memo(Rohusoometsad_nimega);
export default MemoRohusoometsad_nimega;

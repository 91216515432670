import React from "react";

function Kuivendatud_lodu_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 63.56 34.72" {...props}>
      <g data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          strokeWidth={0.75}
          d="M1.89 5.07L63.08.41l-4.66 20.45-2.72 9.01L30.16 33 .4 34.33 1.89 5.07z"
          data-name="kkt piir"
        />
        <text
          transform="translate(8 17)"
          fontSize={8}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="allt\xFC\xFCbid nim."
        >
          {"Kuivendatud"}
          <tspan x={0} y={8}>
            {"lodu"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

const MemoKuivendatud_lodu_alltüüp_nimega = React.memo(
  Kuivendatud_lodu_alltüüp_nimega
);
export default MemoKuivendatud_lodu_alltüüp_nimega;

import React from "react";

function Kõdusoometsad_nimega(props) {
  return (
    <svg viewBox="0 0 268.97 85.16" {...props}>
      <g data-name="Layer 2">
        <path
          d="M 245.938 81.164 L 246.077 81.242 L 246.277 81.312 L 246.487 81.362 L 246.192 80.76 L 246.402 80.81 L 246.612 80.81 L 265.005 36.034 L 265.355 36.034 L 167 40.73 L 213.9 40.73 L 243.66 39.4 L 244.05 39.4 L 261.6 37.25 L 246.53 80 L 172.24 76.54 L 143.89 71.44 L 189.779 80.711 L 246.512 80.961 L 247.454 80.596 L 246.497 80.281 L 245.938 81.164 M 0 0 L 12.64 16.63 L 30.59 33 L 56 49.88 L 81.47 60.69 L 142.83 76.32 L 171.67 81.51 L 250 85.16 L 269 31.29 L 243.45 34.42 L 213.69 35.75 L 167.43 35.75 L 130.69 32.42 L 76.48 23.61 L 45.56 15.8 L 0 0 Z"
          fill="#861811"
          id="background"
        />
        <text
          transform="translate(85 55)"
          fontSize={9}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          {"K\xD5DUSOOMETSAD"}
        </text>
      </g>
    </svg>
  );
}

const MemoKõdusoometsad_nimega = React.memo(Kõdusoometsad_nimega);
export default MemoKõdusoometsad_nimega;

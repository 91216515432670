import React from "react";

function Sambliku_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 120.45 89.29" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M41.6.64l10.14 9.98 12.97 8.48 15.46 7.48 10.81 4.16 28.93 5.98-4.16 46.06-82.47 5.99L.63 30.84l9.54-8.91L41.6.64z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M83.33 47.37l.96-.28" />
          <path
            strokeDasharray="1.9 1.9"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M86.12 46.56l31.92-9.29"
          />
          <path
            className="prefix__cls-2"
            d="M118.95 37l.96-.28M48.75 67.98l1-.06"
          />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M51.71 67.8l30.53-1.91"
          />
          <path
            className="prefix__cls-2"
            d="M83.22 65.83l1-.06M40.55 1.25l.16.99"
          />
          <path
            strokeDasharray="2.13 2.13"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M41.07 4.34l2.68 15.72"
          />
          <path className="prefix__cls-2" d="M43.92 21.11l.17.98-.77.64" />
          <path
            strokeDasharray="1.84 1.84"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M41.91 23.91L28.5 35.05"
          />
          <path className="prefix__cls-2" d="M27.79 35.64l-.77.64-.98-.21" />
          <path
            strokeDasharray="1.91 1.91"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M24.18 35.67L2.7 31.1"
          />
          <path
            className="prefix__cls-2"
            d="M1.77 30.9l-.98-.21M33.28 88.77l.6-.81"
          />
          <path
            strokeDasharray="2.17 2.17"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M35.18 86.22L47.5 69.66"
          />
          <path className="prefix__cls-2" d="M48.15 68.78l.6-.8-.51-.86" />
          <path
            strokeDasharray="1.94 1.94"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M47.27 65.45L30.18 36.18"
          />
          <path className="prefix__cls-2" d="M29.69 35.35l-.51-.87.77-.63" />
          <path
            strokeDasharray="2.1 2.1"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M31.57 32.5l8.87-7.37"
          />
          <path className="prefix__cls-2" d="M41.25 24.46l.77-.64.87.49" />
          <path
            strokeDasharray="1.98 1.98"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M44.61 25.29L81.6 46.38"
          />
          <path className="prefix__cls-2" d="M82.46 46.87l.87.5.05.99" />
          <path
            strokeDasharray="1.82 1.82"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M83.47 50.19l.66 13.67"
          />
          <path className="prefix__cls-2" d="M84.17 64.77l.05 1 .88.47" />
          <path
            strokeDasharray="1.99 1.99"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M86.85 67.19L114 81.83"
          />
          <path className="prefix__cls-2" d="M114.87 82.31l.88.47" />
        </g>
        <text
          transform="translate(42.86 53.57)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Sambliku"}
        </text>
      </g>
    </svg>
  );
}

const MemoSambliku_alltüüp_nimega = React.memo(Sambliku_alltüüp_nimega);
export default MemoSambliku_alltüüp_nimega;

import React from "react";

function Raba_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 78.67 111.64" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M.5.53l26.27 1.33 18.29 5.32 5.16 17.62 27.93 23.45-1.33 13.41-19.12 49.27-44.07-23.61-7.81-27.44L.5 25.13V.53z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M57.7 110.93l-.5-.87" />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M56.23 108.34L37.47 75.18l-9.42-5.21-8.32-13.41-5.98-19.07.39-16.94"
          />
          <path className="prefix__cls-2" d="M14.17 19.57l.02-1 1-.05" />
          <path
            strokeDasharray="1.87 1.87"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M17.06 18.42l8.77-.44 5.02 1.54"
          />
          <path className="prefix__cls-2" d="M31.75 19.8l.95.29.14.99" />
          <path
            strokeDasharray="1.96 1.96"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M33.12 23.02l1.02 7.16 11.98 9.52"
          />
          <path className="prefix__cls-2" d="M46.88 40.31l.78.62-.08 1" />
          <path
            strokeDasharray="2 2"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M47.4 43.92l-.73 8.21-4.66 12.33-3.76 8.88"
          />
          <path
            className="prefix__cls-2"
            d="M37.86 74.26l-.39.92M32.7 20.09l.69-.72"
          />
          <path
            strokeDasharray="1.76 1.76"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M34.61 18.09l9.15-9.55"
          />
          <path
            className="prefix__cls-2"
            d="M44.37 7.9l.69-.72M47.67 40.93l.97.23"
          />
          <path
            strokeDasharray="1.96 1.96"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M50.54 41.62l25.68 6.16"
          />
          <path className="prefix__cls-2" d="M77.18 48.01l.97.24M.5.53l.6.79" />
          <path
            strokeDasharray="1.88 1.88"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M2.24 2.82l10.78 14.2"
          />
          <path className="prefix__cls-2" d="M13.59 17.77l.6.8" />
        </g>
        <text
          transform="translate(21.98 47.8)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Raba"}
        </text>
      </g>
    </svg>
  );
}

const MemoRaba_alltüüp_nimega = React.memo(Raba_alltüüp_nimega);
export default MemoRaba_alltüüp_nimega;

import React from "react";

function Karusambla_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 87.57 75.89" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M50.5 8.1l25.72 22.43 8.97 26.9 1.83 17.96H66.57l-32.92-6.65L5.71 45.29.56 27.67l16.02-16.91L28.83.56 50.5 8.1z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M76.22 30.53l-.98.19" />
          <path
            strokeDasharray="1.84 1.84"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M73.44 31.08l-13.52 2.7"
          />
          <path className="prefix__cls-2" d="M59.02 33.96l-.99.19.32.95" />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M58.98 36.97l3.16 9.37.81 8.78"
          />
          <path className="prefix__cls-2" d="M63.04 56.1l.09 1 .8.6" />
          <path
            strokeDasharray="1.87 1.87"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M65.41 58.84l20.07 15.37"
          />
          <path
            className="prefix__cls-2"
            d="M86.23 74.78l.79.61M63.13 57.1l-.99-.07"
          />
          <path
            strokeDasharray="1.94 1.94"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M60.2 56.9l-10.26-.69-13.74-3.96L24 43.24l-3.78-9.04"
          />
          <path className="prefix__cls-2" d="M19.85 33.3l-.39-.92.7-.72" />
          <path
            strokeDasharray="2.04 2.04"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M21.58 30.2l4.97-5.14 7.87-1.22 8.04-2.27"
          />
          <path className="prefix__cls-2" d="M43.44 21.29l.96-.27.72.69" />
          <path
            strokeDasharray="1.88 1.88"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M46.48 23.02l10.16 9.79"
          />
          <path
            className="prefix__cls-2"
            d="M57.31 33.46l.72.69M36.2 52.25l-.15.99"
          />
          <path
            strokeDasharray="2.1 2.1"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M35.72 55.31l-1.76 11.4"
          />
          <path
            className="prefix__cls-2"
            d="M33.8 67.75l-.15.99M.56 27.67l.97.24"
          />
          <path
            strokeDasharray="1.94 1.94"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M3.42 28.38l14.13 3.52"
          />
          <path
            className="prefix__cls-2"
            d="M18.49 32.14l.97.24M34.42 23.84l-.23-.97"
          />
          <path
            strokeDasharray="1.99 1.99"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M33.72 20.93L29.29 2.51"
          />
          <path className="prefix__cls-2" d="M29.06 1.54l-.23-.97" />
        </g>
        <text
          transform="translate(21.63 44.91)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Karusambla"}
        </text>
      </g>
    </svg>
  );
}

const MemoKarusambla_alltüüp_nimega = React.memo(Karusambla_alltüüp_nimega);
export default MemoKarusambla_alltüüp_nimega;

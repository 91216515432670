import React from "react";

function Jänesekapsa_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 126.66 88.25" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:transparent;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M.53 46.84l3.24-23.68L27.41 1.94l47-1.44 34.03 12.42 17.51 20.95-22.28 17.96-1.55 31.14-29.5 4.77-39.23-6.54L.53 46.84z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M61.88 14.03l.95.32" />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M64.69 14.99l12.64 4.36 7.87 10.6"
          />
          <path className="prefix__cls-2" d="M85.78 30.74l.6.8 1 .06" />
          <path
            strokeDasharray="1.98 1.98"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M89.36 31.71l34.6 2.04"
          />
          <path
            className="prefix__cls-2"
            d="M124.95 33.81l1 .06M24.42 26.22l-.21.98"
          />
          <path
            strokeDasharray="1.73 1.73"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M23.85 28.89l-1.26 5.92"
          />
          <path className="prefix__cls-2" d="M22.41 35.66l-.21.98.68.73" />
          <path
            strokeDasharray="1.95 1.95"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M24.2 38.81l13.96 15.12 22.17 3.77 10.36-2.24"
          />
          <path className="prefix__cls-2" d="M71.64 55.25l.98-.21.11-.99" />
          <path
            strokeDasharray="2.01 2.01"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M72.95 52.05l1.23-10.98"
          />
          <path className="prefix__cls-2" d="M74.3 40.07l.11-.99.84-.53" />
          <path
            strokeDasharray="1.74 1.74"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M76.72 37.62l8.08-5.09"
          />
          <path
            className="prefix__cls-2"
            d="M85.53 32.07l.85-.53M4.06 22.89l.99.17"
          />
          <path
            strokeDasharray="2.07 2.07"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M7.09 23.39l15.32 2.5"
          />
          <path className="prefix__cls-2" d="M23.43 26.06l.99.16.73-.68" />
          <path
            strokeDasharray="1.92 1.92"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M26.55 24.23l10.95-10.2h22.42"
          />
          <path className="prefix__cls-2" d="M60.88 14.03h1l.68-.74" />
          <path
            strokeDasharray="1.83 1.83"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M63.8 11.95l9.31-10.04"
          />
          <path
            className="prefix__cls-2"
            d="M73.73 1.24l.68-.74M102.12 82.97l-.73-.68"
          />
          <path
            strokeDasharray="2.03 2.03"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M99.92 80.89L74.08 56.43"
          />
          <path
            className="prefix__cls-2"
            d="M73.35 55.73l-.73-.69M33.39 81.2l.18-.99"
          />
          <path
            strokeDasharray="1.98 1.98"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M33.91 78.27l3.91-22.38"
          />
          <path className="prefix__cls-2" d="M37.99 54.92l.17-.99" />
        </g>
        <text
          transform="translate(26.86 37.17)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"J\xE4nesekapsa"}
        </text>
      </g>
    </svg>
  );
}

const MemoJänesekapsa_alltüüp_nimega = React.memo(Jänesekapsa_alltüüp_nimega);
export default MemoJänesekapsa_alltüüp_nimega;

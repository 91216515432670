import React from "react";

function Soovikumetsad_nimega(props) {
  return (
    <svg viewBox="0 0 175.79 127.75" {...props}>
      <defs>
        <style>{".prefix__cls-3{letter-spacing:-.02em}"}</style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          d="M62.59 5.73L97 11.47l.81.14.81-.13 27-4.37 40.1 38.38-5.7 22.21-.45 1.75.77 1.63 9.24 19.61-7.58 6.54-26.68-3.53-3-.41-1.74 2.54-8.64 12.64-.23.32-.16.36-6.31 13.28-30.42-3h-.55l-26.1.31-16.63-12.18-26-23-1.63-16v-.55l-.18-.52-7.28-21.8 11-2.75 34.62 8.15 5.93 1.4.21-6.1.79-22.32 3.59-18.34M58.63 0L54 23.5l-.8 22.72-35.81-8.42L0 42.16l9 26.9L10.8 87l27.6 24.45 18.12 13.3 27.77-.33 33.92 3.32 7.79-16.45 8.65-12.64 28.93 3.83L175.79 92l-10.86-23 6.43-25.05-44-42.13-29.51 4.72L58.63 0z"
          fill="#6ac6db"
          id="prefix__kasvukohat\xFC\xFCp"
        />
        <path
          d="M 62.59 5.73 C 127.585 266.544 97 11.47 97 11.47 L 97.81 11.61 L 98.62 11.48 L 125.62 7.11 L 83.085 11.191 L 115.967 124.591 C 116.046 124.779 127.855 101.897 128.949 101.345 L 160.34 71.08 L 120.942 96.263 L 162 97.23 L 135.32 93.7 L 132.32 93.29 L 130.58 95.83 L 121.94 108.47 L 121.71 108.79 L 121.55 109.15 L 100.229 72.217 L 73.249 106.077 L 72.699 106.077 L 58.17 119.74 L 72.385 95.832 L 13.788 51.857 L 13.91 68.56 L 13.91 68.01 L 13.73 67.49 L 66.26 108.811 L 77.327 48.446 L 52.07 51.09 L 58 52.49 L 57.658 120.617 L 132.198 96.907 L 62.59 5.73 M 58.63 0 L 54 23.5 L 53.2 46.22 L 17.39 37.8 L 0 42.16 L 9 69.06 L 10.8 87 L 38.4 111.45 L 56.52 124.75 L 84.29 124.42 L 118.21 127.74 L 126 111.29 L 134.65 98.65 L 163.58 102.48 L 175.79 92 L 164.93 69 L 171.36 43.95 L 127.36 1.82 L 97.85 6.54 L 58.63 0 Z"
          fill="#6ac6db"
          id="background"
        />
        <text
          transform="translate(65 35)"
          fontSize={9}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__t\xFC\xFCbid_nim_"
          data-name="t\xFC\xFCbid nim."
        >
          {"SOOVIKUMETSAD"}
        </text>
      </g>
    </svg>
  );
}

const MemoSoovikumetsad_nimega = React.memo(Soovikumetsad_nimega);
export default MemoSoovikumetsad_nimega;

import React from "react"
import { graphql, navigate } from "gatsby"
import "./OrdinatePage.css"
import { get } from "lodash"
import arrowSVGWhite from "../../images/arrowWhite.svg";
import NavOverlay from "../../components/NavOverlay/NavOverlay"
import OrdinateScheme from "../../components/OrdinateScheme/OrdinateScheme"
import SchemeLegend from "../../components/SchemeLegend/SchemeLegend"

const OrdinatePage = ({ data }) => (
  <>
    <NavOverlay
      menuData={get(data, "wpgraphql.menu")}
      hasBackButton
    />
    <div className="ordinate-content-container">
      <button className="backArrow backArrowStatic ordinateButton" onClick={() => navigate(-1)}>
        <div className="backArrowBackgroundCircle">
          <img alt="" src={arrowSVGWhite} className="arrowSVG" />
        </div>
      </button>
      <div className="ordinateWrapper">
        <OrdinateScheme className="OrdinateScheme" menuData={get(data, "wpgraphql.menu")}/>
      </div>
      <SchemeLegend />
    </div>
  </>
)

export default OrdinatePage

export const query = graphql` 
  query SchemePageQuery {
    wpgraphql {
      menu(id: "bmF2X21lbnU6Nw==") {
        name
        menuItems(first: 100, where: {parentId: "null"}) {
          nodes {
            label
            id
            childItems {
              nodes {
                label
                url
                id
                parentId
                childItems {
                  nodes {
                    label
                    url
                    id
                    parentId
                    childItems {
                      nodes {
                        label
                        url
                        id
                        parentId
                        childItems {
                          nodes {
                            label
                            url
                            id
                            parentId
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

import React from "react";

function Mustika_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 113.62 107.67" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M113.08 60.82L80.21 26.45l3.24-23.67L63.31.52 30.22 20.58.57 53.56l6.48 19.45 21.67 7.54 25.73 22.42 17.39-4.36 35.8 8.43.78-22.72 4.66-23.5z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M30.16 20.8l.11 1" />
          <path
            strokeDasharray="2.16 2.16"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M30.49 23.95l.79 7.53"
          />
          <path className="prefix__cls-2" d="M31.39 32.55l.1 1-.64.77" />
          <path
            strokeDasharray="1.89 1.89"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M29.64 35.77l-11.53 13.8"
          />
          <path className="prefix__cls-2" d="M17.5 50.3l-.64.76.37.93" />
          <path
            strokeDasharray="1.6 1.6"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M17.81 53.48l2.05 5.22"
          />
          <path className="prefix__cls-2" d="M20.15 59.44l.37.93-.73.69" />
          <path
            strokeDasharray="1.83 1.83"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M18.46 62.31L8.45 71.7"
          />
          <path
            className="prefix__cls-2"
            d="M7.78 72.33l-.73.68M31.49 33.55l.87-.5"
          />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M34.06 32.05l14.23-8.26 11.25.45 22.73-20.4"
          />
          <path
            className="prefix__cls-2"
            d="M83 3.18l.75-.67M71.73 77.67l.77.63"
          />
          <path
            strokeDasharray="1.93 1.93"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M74 79.52l32.12 26.28"
          />
          <path
            className="prefix__cls-2"
            d="M106.87 106.41l.77.63M59.54 24.24l-.21.98"
          />
          <path
            strokeDasharray="2.07 2.07"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M58.9 27.24l-1.49 7.09"
          />
          <path className="prefix__cls-2" d="M57.19 35.34l-.2.98.69.72" />
          <path
            strokeDasharray="2.08 2.08"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M59.13 38.53L72.86 52.7"
          />
          <path className="prefix__cls-2" d="M73.58 53.45l.7.72-.3.95" />
          <path
            strokeDasharray="2 2"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M73.38 57.03l-2.15 6.84.43 11.8"
          />
          <path className="prefix__cls-2" d="M71.69 76.67l.04 1-.96-.26" />
          <path
            strokeDasharray="1.95 1.95"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M68.88 76.9l-14.43-3.89-7.12 2.37"
          />
          <path className="prefix__cls-2" d="M46.41 75.69l-.95.31-.72-.69" />
          <path
            strokeDasharray="1.9 1.9"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M43.37 73.99l-11.88-11.4-9.06-1.83"
          />
          <path
            className="prefix__cls-2"
            d="M21.5 60.57l-.98-.2M113.08 60.82l-.99-.17"
          />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M110.15 60.32L76.23 54.5"
          />
          <path className="prefix__cls-2" d="M75.27 54.33l-.99-.16" />
        </g>
        <text
          transform="translate(31.78 56.99)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Mustika"}
        </text>
      </g>
    </svg>
  );
}

const MemoMustika_alltüüp_nimega = React.memo(Mustika_alltüüp_nimega);
export default MemoMustika_alltüüp_nimega;

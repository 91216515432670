import React from "react";

function Sinilille_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 122.52 79.31" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M22.79.56l93.12 12.97-4.27 9.47 6.59 28.21 3.72 25.61-24.28 1.99-45.22-2.43-17.52-20.96L.9 43.01l13.97-14.19L22.79.56z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M119.7 61.13l-1-.02" />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M116.65 61.06l-16.87-.42-22.93-2.25"
          />
          <path className="prefix__cls-2" d="M75.83 58.29l-.99-.09-.56-.83" />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M73.12 55.67l-4.71-6.95-18.06-6.77"
          />
          <path className="prefix__cls-2" d="M49.39 41.59l-.94-.35.77-.65" />
          <path
            strokeDasharray="2.16 2.16"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M50.86 39.2l5.76-4.88"
          />
          <path className="prefix__cls-2" d="M57.45 33.62l.76-.64.04-1" />
          <path
            strokeDasharray="1.77 1.77"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M58.33 30.21l.41-9.76"
          />
          <path className="prefix__cls-2" d="M58.78 19.57l.04-1 1 .08" />
          <path
            strokeDasharray="2.04 2.04"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M61.85 18.82l47.77 4.01"
          />
          <path
            className="prefix__cls-2"
            d="M110.64 22.92l1 .08M58.82 18.57l-.9-.45"
          />
          <path
            strokeDasharray="2.01 2.01"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M56.12 17.22L24.59 1.45"
          />
          <path
            className="prefix__cls-2"
            d="M23.69 1l-.9-.44M48.46 41.24l-1 .03"
          />
          <path
            strokeDasharray="1.98 1.98"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M45.48 41.35L2.89 42.94"
          />
          <path
            className="prefix__cls-2"
            d="M1.9 42.97l-1 .04M52.45 76.37l.77-.63"
          />
          <path
            strokeDasharray="2.06 2.06"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M54.82 74.44l18.44-14.96"
          />
          <path className="prefix__cls-2" d="M74.06 58.83l.78-.63" />
        </g>
        <text
          transform="translate(64.33 45.32)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Sinilille"}
        </text>
      </g>
    </svg>
  );
}

const MemoSinilille_alltüüp_nimega = React.memo(Sinilille_alltüüp_nimega);
export default MemoSinilille_alltüüp_nimega;

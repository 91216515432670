import React from "react";
import { navigate } from "gatsby";

function Kuivendatud_raba_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 69.84 57.82" {...props}>
      <g data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          strokeWidth={0.75}
          d="M.73.71l21.6 8.98 29.2 10.84 17.58 36.65-45.95-15.9-14.3-22.61L.73.71z"
          data-name="kkt piir"
        />
        <text
          transform="translate(20.57 27.79)"
          fontSize={7}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="allt\xFC\xFCbid nim."
        >
          <tspan letterSpacing="-.03em">{"K"}</tspan>
          <tspan x={3.89} y={0}>
            {"ui"}
          </tspan>
          <tspan x={8.83} y={0} letterSpacing="-.01em">
            {"v"}
          </tspan>
          <tspan x={11.88} y={0}>
            {"end."}
          </tspan>
          <tspan x={-5.24} y={8} />
          <tspan x={15.97} y={8} letterSpacing="-.02em">
            {"r"}
          </tspan>
          <tspan x={18.14} y={8}>
            {"aba"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

const MemoKuivendatud_raba_alltüüp_nimega = React.memo(
  Kuivendatud_raba_alltüüp_nimega
);
export default MemoKuivendatud_raba_alltüüp_nimega;

import React from "react";

function Palumetsad_nimega(props) {
  return (
    <svg viewBox="0 0 175.42 196.81" {...props}>
      <g data-name="Layer 2" id="prefix__Layer_2">
        <path
          d="M159.09 5.23l11 22.68-7.09 25.2L151.37 65l-45 1.38-1.83.06-1.36 1.22-47.55 42.62-1.21 1.1-.34 1.62-2.55 12.09-.55 2.6 1.85 1.91 17.29 17.84 1.14 1.18 1.61.27 33.74 5.79-3.61 18.43-.08.4v.4l-.57 16.64-29.87-7-1.18-.28-1.18.29-12.9 3.24-7.52-22.58-.47-1.22-.95-.88-14.47-13.3-1-.91-1.31-.29-10.5-2.36-2.58-.57-1.93 1.81-7.77 7.29-3.1-9.29 27.71-30.81L35 111.9l-.4-2.37-2.3-13.91 4.31-25 34.8-43.38.13-.17.12-.18L82.44 10.8l76.65-5.57m3-5.23L79.64 6 67.51 24.11 31.88 68.5l-4.67 27.11 2.44 14.74L0 143.33l6.48 19.45L20 150.14l10.48 2.33 14.46 13.3 9 27 17.39-4.36 35.81 8.43.77-22.72 4.66-23.5-38.8-6.66-17.35-17.87L59 114l47.55-42.68 47-1.44 14-14.19 7.93-28.27L162.11 0z"
          fill="#ee741f"
        />
        <path 
          d="M 153.055 67.134 L 170.09 27.91 L 145.686 34.879 L 151.37 65 L 106.37 66.38 L 104.54 66.44 L 103.18 67.66 L 55.63 110.28 L 54.42 111.38 L 54.08 113 L 19.942 145.42 L 45.135 161.156 L 55.824 188.7 L 70.12 147.44 L 71.26 148.62 L 70.131 143.16 L 58.906 170.893 L 103 173.11 L 102.92 173.51 L 104.866 166.992 L 61.038 172.721 L 72.48 183.55 L 74.102 175.926 L 70.12 183.56 L 106.083 173.719 L 49.7 164.22 L 49.23 163 L 48.28 162.12 L 33.81 148.82 L 32.81 147.91 L 31.5 147.62 L 21 145.26 L 18.42 144.69 L 16.49 146.5 L 8.72 153.79 C 8.72 153.79 35.634 142.182 39.984 137.886 C 49.812 128.18 50.22 110.886 50.22 110.886 L 35 111.9 L 34.6 109.53 L 30.188 147.953 L 58.234 111.209 L 71.41 27.24 L 71.54 27.07 L 67.848 27.498 L 56.541 112.732 L 111.321 68.01 M 162.09 0 L 79.64 6 L 67.51 24.11 L 31.88 68.5 L 27.21 95.61 L 29.65 110.35 L 0 143.33 L 6.48 162.78 L 20 150.14 L 30.48 152.47 L 44.94 165.77 L 53.94 192.77 L 71.33 188.41 L 107.14 196.84 L 107.91 174.12 L 112.57 150.62 L 73.77 143.96 L 56.42 126.09 L 59 114 L 106.55 71.32 L 153.55 69.88 L 167.55 55.69 L 175.48 27.42 L 162.11 0 L 162.09 0 Z"
          fill="#ee741f"
          id="background"
        />
        <text
          transform="translate(54 65)"
          fontSize={9}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          <tspan y={-2}>
            {"PALUMETSAD"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

const MemoPalumetsad_nimega = React.memo(Palumetsad_nimega);
export default MemoPalumetsad_nimega;

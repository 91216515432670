import React from "react";

function Kastikuloo_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 134.26 59.02" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M14.05 45.51L.75 18.07 133.44.61l-26.27 57.87-93.12-12.97z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M127.95 11.92l-.99.16" />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M124.93 12.41L57.01 23.56"
          />
          <path
            className="prefix__cls-2"
            d="M56 23.73l-.99.16M14.05 45.51l.98-.2"
          />
          <path
            strokeDasharray="2.02 2.02"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M17.02 44.9l46.42-9.48 47.79 9.36"
          />
          <path className="prefix__cls-2" d="M112.22 44.98l.99.19-.99-.19" />
          <path
            strokeDasharray="2.03 2.03"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M110.23 44.59l-46.79-9.17-7.24-9.9"
          />
          <path className="prefix__cls-2" d="M55.6 24.7l-.59-.81-.99-.11" />
          <path
            strokeDasharray="1.95 1.95"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M52.08 23.58l-49.37-5.3"
          />
          <path className="prefix__cls-2" d="M1.75 18.18l-1-.11" />
        </g>
        <text
          transform="translate(62.92 31.79)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Kastikuloo"}
        </text>
      </g>
    </svg>
  );
}

const MemoKastikuloo_alltüüp_nimega = React.memo(Kastikuloo_alltüüp_nimega);
export default MemoKastikuloo_alltüüp_nimega;

import React from "react";

function TrAn_nimega(props) {
  return (
    <svg viewBox="0 0 81.47 52.43" {...props}>
      <g data-name="Layer 2">
        <path
          fill="#c5e1e6"
          d="M0 0l35.36 9.09 21.06 17.18 20.26 9.42 4.79 16.74L34.7 30.48 0 0z"
          data-name="Layer 12"
        />
        <text
          transform="translate(40.97 32.47)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="allt\xFC\xFCbid nim."
        >
          <tspan letterSpacing="-.02em">{"T"}</tspan>
          <tspan x={3.67} y={0}>
            {"r-an"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

const MemoTrAn_nimega = React.memo(TrAn_nimega);
export default MemoTrAn_nimega;

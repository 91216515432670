import React from "react";

function Pohla_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 149.24 111.68" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M52.94 6.51L135.41.52l13.3 27.44-7.93 28.26-13.96 14.19-47 1.44-23.64 21.22-20.15-2.26-33.08 20.06L.51 96.13l4.67-27.1 35.62-44.4L52.94 6.51z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M126.82 70.41l-.92-.39" />
          <path
            strokeDasharray="1.92 1.92"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M124.13 69.27L96.81 57.65"
          />
          <path className="prefix__cls-2" d="M95.92 57.28l-.92-.39.74-.68" />
          <path
            strokeDasharray="1.77 1.77"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M97.04 55.02l4.56-4.17"
          />
          <path className="prefix__cls-2" d="M102.25 50.25l.73-.68v-1" />
          <path
            strokeDasharray="2.19 2.19"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M102.99 46.38V38.7"
          />
          <path className="prefix__cls-2" d="M102.98 37.61v-1l.99-.19" />
          <path
            strokeDasharray="1.94 1.94"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M105.87 36.06l40.91-7.74"
          />
          <path
            className="prefix__cls-2"
            d="M147.73 28.14l.98-.18M135.41.52l-.87.5"
          />
          <path
            strokeDasharray="2.02 2.02"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M132.78 2.01L92.12 25.08l-33.02.52"
          />
          <path className="prefix__cls-2" d="M58.09 25.61l-1 .02-.21-.98" />
          <path
            strokeDasharray="1.95 1.95"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M56.47 22.75L53.36 8.44"
          />
          <path
            className="prefix__cls-2"
            d="M53.15 7.49l-.21-.98M92.12 25.08l.69.72"
          />
          <path
            strokeDasharray="1.98 1.98"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M94.16 27.24l7.46 7.92"
          />
          <path
            className="prefix__cls-2"
            d="M102.3 35.88l.69.73M95 56.89l-.99.07"
          />
          <path
            strokeDasharray="1.99 1.99"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M92.03 57.09l-20.42 1.35-12.19 10.53-2.7 21.86"
          />
          <path
            className="prefix__cls-2"
            d="M56.6 91.81l-.13.99M57.09 25.63l-.6.8"
          />
          <path
            strokeDasharray="2.02 2.02"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M55.28 28.04l-20.8 27.63-4.32 13.3 2.06 6.62"
          />
          <path className="prefix__cls-2" d="M32.52 76.55l.3.96.86-.51" />
          <path
            strokeDasharray="2.12 2.12"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M35.51 75.93l14.82-8.68 7.07 1.34"
          />
          <path
            className="prefix__cls-2"
            d="M58.44 68.79l.98.18M32.82 77.51l-.67.74"
          />
          <path
            strokeDasharray="2.05 2.05"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M30.79 79.78l-26.55 29.8"
          />
          <path
            className="prefix__cls-2"
            d="M3.56 110.35l-.67.74M34.48 55.67l-.91.41"
          />
          <path
            strokeDasharray="2.01 2.01"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M31.74 56.92L7 68.19"
          />
          <path className="prefix__cls-2" d="M6.09 68.61l-.91.42" />
        </g>
        <text
          transform="translate(59.82 45.03)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"Pohla"}
        </text>
      </g>
    </svg>
  );
}

const MemoPohla_alltüüp_nimega = React.memo(Pohla_alltüüp_nimega);
export default MemoPohla_alltüüp_nimega;

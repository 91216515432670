import React from "react";

function Loometsad_nimega(props) {
  return (
    <svg viewBox="0 0 132.68 106.08" {...props}>
      <g data-name="Layer 2" id="prefix__Layer_2">
        <path
          d="M99.58 6.58L127 48.65l-23.6 52-86.76-12.12L5.1 64.74l3.63-40.12h32l.35-.05 18.62-2.66.39-.06.37-.11 24.94-7.65.5-.09.44-.24 13.24-7.18M101.26 0L84 9.31 59 17l-18.6 2.62H4.16L0 65.68l13.3 27.43 93.11 13 26.27-57.86L101.26 0z"
          fill="#d1cc8d"
        />
        <path
          d="M 106.284 102.435 L 127 48.65 L 103.4 100.65 L 92.591 35.211 L 90.305 11.636 L 8.73 24.62 L 40.73 24.62 L 41.08 24.57 L 59.7 21.91 L 60.09 21.85 L 60.46 21.74 L 85.4 14.09 L 85.9 14 L 86.34 13.76 L 89.784 100.884 M 101.26 0 L 84 9.31 L 59 17 L 40.4 19.62 L 4.16 19.62 L 0 65.68 L 13.3 93.11 L 106.41 106.11 L 132.68 48.25 L 101.26 0 Z"
          fill="#d1cc8d"
          id="background"
        />
        <text
          transform="translate(35 62)"
          fontSize={9}
          fill="#1d1d1b"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          data-name="t\xFC\xFCbid nim."
        >
          {"LOOMETSAD"}
        </text>
      </g>
    </svg>
  );
}

const MemoLoometsad_nimega = React.memo(Loometsad_nimega);
export default MemoLoometsad_nimega;

import React from "react";

function Sõnajala_alltüüp_nimega(props) {
  return (
    <svg viewBox="0 0 67.98 76.64" {...props}>
      <defs>
        <style>
          {
            ".prefix__cls-2{fill:none;stroke:#e74011;stroke-miterlimit:10;stroke-width:.5px}"
          }
        </style>
      </defs>
      <g id="prefix__Layer_2" data-name="Layer 2">
        <path
          fill="none"
          stroke="#e74011"
          strokeMiterlimit={10}
          d="M65.26 76.11l2.22-34.14L65.93.51l-33.7.95L6.96 12.71.53 37.76l10.86 23.05 26.16 13.53 27.71 1.77z"
          id="prefix__kkt_piir"
          data-name="kkt piir"
        />
        <g
          id="prefix__kk_alltuubi_piir"
          data-name="kk alltuubi piir"
        >
          <path className="prefix__cls-2" d="M6.96 12.71l.76.65" />
          <path
            strokeDasharray="1.98 1.98"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M9.22 14.64l11.3 9.63"
          />
          <path className="prefix__cls-2" d="M21.27 24.92l.76.65.92-.41" />
          <path
            strokeDasharray="2.08 2.08"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M24.84 24.31l8.11-3.62 31.5-3.23"
          />
          <path
            className="prefix__cls-2"
            d="M65.49 17.35l.99-.1M22.03 25.57l-.32.94"
          />
          <path
            strokeDasharray="1.97 1.97"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M21.06 28.37l-2.26 6.52"
          />
          <path className="prefix__cls-2" d="M18.48 35.82l-.33.94.46.89" />
          <path
            strokeDasharray="1.95 1.95"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M19.51 39.38l5.51 10.66 13.53 7.78 25.96.41"
          />
          <path
            className="prefix__cls-2"
            d="M65.48 58.25l1 .01M25.02 50.04l-.78.62"
          />
          <path
            strokeDasharray="2.2 2.2"
            strokeWidth={0.5}
            fill="none"
            stroke="#e74011"
            strokeMiterlimit={10}
            d="M22.52 52.02l-9.48 7.49"
          />
          <path className="prefix__cls-2" d="M12.18 60.19l-.79.62" />
        </g>
        <text
          transform="translate(25.25 39.43)"
          fontSize={9}
          fill="#e41822"
          fontFamily="FuturaPT-Medium,Futura PT"
          fontWeight={500}
          id="prefix__allt\xFC\xFCbid_nim_"
          data-name="allt\xFC\xFCbid nim."
        >
          {"S\xF5najala"}
        </text>
      </g>
    </svg>
  );
}

const MemoSõnajala_alltüüp_nimega = React.memo(Sõnajala_alltüüp_nimega);
export default MemoSõnajala_alltüüp_nimega;
